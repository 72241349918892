import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  TabSelectComponent,
  TabSelectContentComponent,
  TabSelectOptionsComponent,
  TabSelectOptionComponent,
} from "./components";
import { withTheme } from "styled-components";

interface TabValue {
  label: string;
  value: string;
}
interface PassProps {
  id: string;
  value: string;
  values: TabValue[];
  onChange: (val: TabValue) => void;
  screenWidth: number;
  style?: React.CSSProperties;
  background?: string;
  theme?: T.Core.BaseTheme.ThemeInterface;
  highlightSelectedStyle?: "bubble" | "underline";
}
interface FullProps extends PassProps { }
interface State { }

class TabSelectClass extends React.Component<FullProps, State> {
  onOptionSelect(v: TabValue) {
    this.props.onChange(v);
  }

  options() {
    const { values, value, highlightSelectedStyle } = this.props;

    return (
      <ScrollContainer>
        <TabSelectOptionsComponent>
          {values.map((v, i) => {
            return (
              <TabSelectOptionComponent
                key={i}
                id={`TabSelectOption-${v.value}`}
                onClick={() => this.onOptionSelect(v)}
                isHighlighted={v.value === value}
                highlightSelectedStyle={highlightSelectedStyle}
              >
                {v.label}
              </TabSelectOptionComponent>
            );
          })}
        </TabSelectOptionsComponent>
      </ScrollContainer>
    );
  }

  render() {
    const background =
      this.props.background || this.props.theme!.box.background;
    const { style } = this.props;

    return (
      <TabSelectComponent
        id={this.props.id}
        style={style}
        background={background}
      >
        <TabSelectContentComponent>
          {this.options()}
        </TabSelectContentComponent>
      </TabSelectComponent>
    );
  }
}

export const TabSelect = withTheme((props: PassProps) => (
  <TabSelectClass {...props} />
));
