import * as React from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { Dish } from "./dish";
import { styled, shadeAlternate, Button } from "@lib/components";
import { Circle, CircleWrapper } from "./components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../../mobx/component";

interface Props extends WithTranslation { }

const Wrapper = styled("div")`
  position: relative;
  padding: 40px 20px 100px 20px;
  background: ${({ theme }) => shadeAlternate(0.03, theme.colors.background)};
`;

const Content = styled("div")`
  position: relative;
  z-index: 1;
  max-width: ${({ theme }) => theme.content_width.lg}px;
  width: 100%;
  margin: 0 auto;
`;

const DishGrid = styled("div") <{ enableCondensedMenu: boolean; tagsApplied: boolean; dishStyle: "list" | "list-rounded" | "card" }>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  .col {
    width: 100%;
    margin-bottom: ${({ tagsApplied, dishStyle }) => (tagsApplied && dishStyle !== "card") ? 30 : 20}px;
  }
  @media (max-width: 767px) {
     max-width: 480px;
     margin: 0 auto;
	 .col {
		${({ enableCondensedMenu }) => enableCondensedMenu && `
    		margin-bottom: 0px;
  		`}
	 }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
     flex-direction: row;
     .col {
        width: calc(50% - 10px);
        margin-right: 19.9px;
        &:nth-child(2n) {
          margin-right: 0;
        }
     }
  }
  @media (min-width: 1024px) {
     flex-direction: row;
     .col {
        width: calc(33% - 10px);
        margin-right: 19.8px;
        &:nth-child(3n) {
          margin-right: 0;
        }
     }
  }
`;

const DishGridWrapper = styled.div`
  @media (max-width: 767px) {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
  margin-top: 40px;
  &:first-child {
    margin-top: 0;
  }
  h2 {
    padding: 0px;
    font-size: 18px;
    margin-bottom: 4px;
  }

`;

@inject("store") @observer
class MenusExperimentalClass extends MobxComponent<Props> {

	handleLazybeforeunveil = (e: any) => {
		const bg = e.target.getAttribute('data-bg');
		if (bg) {
			e.target.style.background = `url(${bg}) no-repeat center center`;
			e.target.style.backgroundSize = 'cover';
		}
	}

	componentDidMount() {
		this.forceUpdate();

		document.addEventListener('lazybeforeunveil', this.handleLazybeforeunveil, true)
	}

	componentWillUnmount() {
		document.removeEventListener('lazybeforeunveil', this.handleLazybeforeunveil, true)
	}

	render() {
		const { store, t } = this.injected;
		const { restaurant } = store;
		const { tagsApplied } = store.dishes;
		const ws = store.restaurant.website.sections;
		const menu = store.menu;
		const doesMenuHaveRestrictions = store.doesMenuHaveRestrictions;
		const menuAvailability = store.isMenuAvailable;
		const enable_condensed_menu = restaurant.website.sections.menus.enable_condensed_menu;
		const doesCategoryHaveRestrictions = store.doesCategoryHaveRestrictions;
		const categoryAvailability = store.isFilterCategoryAvailable;

		const showCategoryDescription = !ws.menus.dish_hide_category_description;

		return (
			<Wrapper id="dish-grid" className="">

				<CircleWrapper>
					<Circle />
				</CircleWrapper>

				{!menu && (
					<Content>
						<div className="m-t-6 p-2 error-bg max400 text-center center round">
							<p className="">{t("store.sections.menus.info.no_menus")}</p>
						</div>
					</Content>
				)}

				{menu && (
					<Content>

						{menu.description && (
							<div className={cn("text-center", doesMenuHaveRestrictions ? "m-b-4" : "m-b-8")}>
								<p className="lhp">{menu.description}</p>
							</div>
						)}

						{doesMenuHaveRestrictions && (
							<div className="m-b-8 text-center">
								<Button size="xs" color="primary-inverse" className="m-b-1" onClick={() => store.modal.toggle("menu-restrictions")}>
									{menuAvailability.available && t("store.sections.menus.info.restrictions")}
									{!menuAvailability.available && t("store.sections.menus.info.not_available")}
								</Button>
							</div>
						)}

						<div>
							{menu.categories.map((category, i) => {
								return (
									<DishGridWrapper key={i}>
										<div className="m-b-2">
											<h2>{category.display_name || category.name}</h2>
											{showCategoryDescription && <p className="lhp italic">{category.description}</p>}
											{doesCategoryHaveRestrictions(category) && (
												<Button
													size="xxs"
													className="m-tb-1"
													color="primary-inverse"
													onClick={() => store.modal.toggle("category-restrictions")}
												>
													{categoryAvailability.available && t("store.sections.category.restrictions")}
													{!categoryAvailability.available && t("store.sections.category.not_available")}
												</Button>
											)}
										</div>

										<DishGrid
											enableCondensedMenu={enable_condensed_menu || false}
											dishStyle={ws.menus.dish_style}
											tagsApplied={tagsApplied}>
											{category.dishes.map((dish, k) => {
												const tags = restaurant.dish_tags.filter((tag) => dish.tags.indexOf(tag._id) !== -1);
												return (
													<Dish
														key={dish._id}
														index={i}
														dish={dish}
														category={category}
														tags={tags}
														className="col"
													/>
												);
											})}
										</DishGrid>
									</DishGridWrapper>
								);
							})}
						</div>

					</Content>
				)}

			</Wrapper>
		);
	}

}

export const MenusExperimental = withTranslation()(MenusExperimentalClass);
