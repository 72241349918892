import * as Sentry from "@sentry/browser";
import { logger } from "@lib/common";
import { config } from "../config";
import Push from "push.js";
import smoothscroll from "smoothscroll-polyfill";

Sentry.init({
	enabled: config.isProduction,
	environment: config.isTest ? "development" : "production",
	dsn: "https://21cea4563e0541efa54bd02854901858@sentry.io/1385873",
	attachStacktrace: true,
	ignoreErrors: [
		// Random plugins/extensions
		"top.GLOBALS",
		// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
		"originalCreateNotification",
		"canvas.contentDocument",
		"MyApp_RemoveAllHighlights",
		"http://tt.epicplay.com",
		"Can\"t find variable: ZiteReader",
		"jigsaw is not defined",
		"ComboSearch is not defined",
		"http://loading.retry.widdit.com/",
		"atomicFindClose",
		// Facebook borked
		"fb_xd_fragment",
		// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
		// reduce this. (thanks @acdha)
		// See http://stackoverflow.com/questions/4113268
		"bmi_SafeAddOnload",
		"EBCallBackMessageReceived",
		// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
		"conduitPage",
	],
	blacklistUrls: [
		// Facebook flakiness
		/graph\.facebook\.com/i,
		// Facebook blocked
		/connect\.facebook\.net\/en_US\/all\.js/i,
		// Woopra flakiness
		/eatdifferent\.com\.woopra-ns\.com/i,
		/static\.woopra\.com\/js\/woopra\.js/i,
		// Chrome extensions
		/extensions\//i,
		/^chrome:\/\//i,
		// Other plugins
		/127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
		/webappstoolbarba\.texthelp\.com\//i,
		/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
	],
	integrations: [
		new Sentry.Integrations.TryCatch(),
		new Sentry.Integrations.Breadcrumbs(),
		new Sentry.Integrations.GlobalHandlers(),
		new Sentry.Integrations.UserAgent(),
	],
});

smoothscroll.polyfill();

mapboxgl.accessToken = config.mapbox_api_key;

Push.config({
	serviceWorker: "/serviceWorker.js",
});

// setConfig({logLevel: "no-errors-please"});

window.activeModals = window.activeModals || [];

if (process.env.NODE_ENV === "production")
	localStorage.debug = "INFO,WARN,ERROR";
else
	localStorage.debug = "INFO,WARN,ERROR,DEV";

window.onunhandledrejection = (e: any) => {
	console.log("Unhandled Promise Rejection");
	logger.captureException(e);
};

declare global {
	interface Window {
		__INITIAL_TRANSLATION__: { lng: string; ns: string; resources: any };
		__INITIAL_THEME__: T.Core.BaseTheme.ThemeInterface;
		__INITIAL_STATE__: any;
		__fbInitialized?: boolean;
	}
}
