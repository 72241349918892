import { styled, shadeAlternate } from "@lib/components";

export const CircleWrapper = styled("div")`
	position: absolute;
	bottom: -100px;
	left: 0;
	right: 0;
`;

export const Circle = styled("div")`
	position: relative;
	height: 200px;
	width: 100%;
	overflow: hidden;
	:before {
		content: "";
		position: absolute;
		height: 200px;
		width: 100%;
		border-radius: 50%;
		bottom: 0;
		background: ${({ theme }) =>
			shadeAlternate(0.03, theme.colors.background)};
	}
`;

export const Wrapper = styled("div")`
	position: relative;
	padding: 40px 20px 100px 20px;
	background: ${({ theme }) => shadeAlternate(0.03, theme.colors.background)};
`;

export const Content = styled("div")`
	position: relative;
	z-index: 1;
	max-width: ${({ theme }) => theme.content_width.lg}px;
	width: 100%;
	margin: 0 auto;
`;

export const DishGrid = styled("div")<{
	tagsApplied: boolean;
	dishStyle: "list" | "list-rounded" | "card";
}>`
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	flex-wrap: wrap;
	.col {
		width: 100%;
		/* margin-bottom: ${({ tagsApplied, dishStyle }) =>
			tagsApplied && dishStyle !== "card" ? 30 : 20}px; */
		margin-bottom: 30px;
	}
	@media (max-width: 767px) {
		max-width: 480px;
		margin: 0 auto;
	}
	@media (min-width: 768px) and (max-width: 1023px) {
		flex-direction: row;
		.col {
			width: calc(50% - 10px);
			margin-right: 19.9px;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}
	@media (min-width: 1024px) {
		flex-direction: row;
		.col {
			width: calc(33% - 10px);
			margin-right: 19.8px;
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}
	@media (max-width: 480px) {
		.list-style #dish-grid {
			padding-left: 0;
			padding-right: 0;
		}
		.list-style #dish-grid h2 {
			font-size: 26px;
		}
		.list-style #dish-grid .col {
			margin-bottom: 0;
			border-bottom: 1px solid #ddd;
		}
		.list-style #dish-grid .col:last-child {
			border-bottom: none;
		}
		.list-style
			#dish-grid
			div[class*="dish__DishComponent"]:not(.htcybr)
			div[class*="dish__Content"]
			div[class*="dish__Tags"] {
			width: auto;
		}
		.list-style #dish-grid div[class*="dish__DishComponent"]:not(.htcybr) {
			max-width: unset;
			width: 100%;
		}
	}
`;

export const DishGridWrapper = styled.div`
	@media (max-width: 767px) {
		max-width: 480px;
		margin-left: auto;
		margin-right: auto;
	}
	margin-top: 40px;
	&:first-child {
		margin-top: 0;
	}
	h2 {
		padding: 0px;
		font-size: 18px;
		margin-bottom: 4px;
	}
`;
