import * as React from "react";
import { observer, inject } from "mobx-react";
import { Button, SelectModal, Input } from "@lib/components";
import { MobxComponent } from "../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";
import { action, observable } from "mobx";

interface Props extends WithTranslation { }
interface State { }

@inject("store") @observer
class OrderConfigTablesClass extends MobxComponent<Props, State> {

	@observable modal: boolean = false;
	tables: Array<{ label: string; value: string; }>;

	constructor(props: Props) {
		super(props);
		this.state = {};
		this.tables = this.injected.store.restaurant.settings.services.dine_in.tables.map((t) => ({
			label: t.name,
			value: t._id,
		}));
	}

	@action setModal = (modal: boolean) => {
		this.modal = modal;
	}

	onSelect = (o: { label: string; value: string }) => {
		console.log("3");
		this.injected.store.order_config.setTable(o.value, o.label);
		this.setModal(false);
	}

	onClose = () => {
		this.setModal(false);
	}

	render() {

		const { t } = this.injected;
		const r = this.injected.store.restaurant;
		const oc = this.injected.store.order_config;

		const tables = r.settings.services.dine_in.tables;
		const table = oc.s.table_id ? tables.find((ta) => ta._id === oc.s.table_id)! : null;

		// console.log("5 - oc.s.table_id = " + oc.s.table_id);

		const buttonText = table ? table.name : t("store.modals.order.config.table.select");

		return (
			<div className="m-t-6">

				<div className="flex-center flex-line max500 center">
					<Button onClick={() => this.setModal(true)} className="min150">
						{buttonText}
					</Button>
					{(table && table.password) && (
						<Input
							value={oc.s.table_password}
							onChange={(e) => oc.setTablePassword(e.target.value)}
							placeholder={t("store.modals.order.config.table.password")}
							className="m-l-2 max150 text-center"
						/>
					)}
				</div>

				{(table && table.password && oc.s.table_password !== table.password) && (
					<div className="m-t-4 text-center">
						<p className="small">{t("store.modals.order.config.table.password_warning")}</p>
					</div>
				)}

				<SelectModal
					innerId="tables-select"
					title={t("store.modals.order.config.table.select_popup_title")}
					level={2}
					width={420}
					active={this.modal}
					onSelect={this.onSelect}
					close={this.onClose}
					options={this.tables}
				/>

			</div>
		);
	}

}

export const OrderConfigTables = withTranslation()(OrderConfigTablesClass);
