import * as React from "react";
import cn from "classnames";
import { observer, inject } from "mobx-react";
import { styled } from "@lib/components";
import { ContentCSS } from "./components";
import { MobxComponent } from "../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";
import Measure, { ContentRect } from "react-measure";

export const Wrapper = styled("div") <{ background: string, text: string }>`
  background: ${({ background }) => background};
  color: ${({ text }) => text};
  padding: 8px 20px;
`;

export const Content = styled("div")`
  ${ContentCSS};
  justify-content: center;
`;


@inject("store") @observer
class NavAlertClass extends MobxComponent<WithTranslation> {

	onComponentResize = (contentRect: ContentRect) => {
		if (contentRect.bounds) {
			this.injected.store.view.update({
				top_alert_height: contentRect.bounds.height,
			});
		}
	}

	render() {
		const { restaurant } = this.injected.store;
		const { alert } = restaurant.website.sections;
		if (!alert || !alert.text) return null;
		return (
			<Measure bounds={true} onResize={this.onComponentResize}>
				{({ measureRef }) => (
					<Wrapper
						ref={measureRef as any}
						background={alert.colors.background}
						text={alert.colors.text}>
						<Content>
							<p className={cn("lhp small text-center", alert.bold && "font-semi-bold")}>
								{alert.text}
							</p>
						</Content>
					</Wrapper>
				)}
			</Measure>
		);
	}

}

// @ts-ignore
export const NavAlert = withTranslation()(NavAlertClass);

