import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import { FormGroup, RotateLoader } from "@lib/components";
import { FacebookButton, GoogleButton } from "./components";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { CwLoginButton } from "../../components/google_login/CwGoogleLoginButton";

interface Props extends WithTranslation { }
interface State { }

@inject("store") @observer
class AuthSocialClass extends MobxComponent<Props, State> {

	render() {

		const { store, t } = this.injected;

		const { fb, google, login } = store.customer.form;

		const accounts = store.restaurant.settings.accounts;

		if (!accounts.facebook.app_id && !accounts.google.api_client_id)
			return null;

		if (store.view.s.is_web_view) {
			return null;
		}

		return (
			<>
				<FormGroup key="social-sign-in" contentClassName="child-mb-10">
					{(!!accounts.facebook.app_id && !fb.error) && (
						<FacebookButton onClick={store.customer.facebookButtonClick}>
							<FaFacebook />
							{!fb.loading && <span className="text">{t("store.modals.auth.login_facebook")}</span>}
							{fb.loading && <RotateLoader color="white" size={2} />}
						</FacebookButton>
					)}
					{(!!accounts.google.api_client_id && !login.error ) && (
						<div id="google-signin-button" style={{ 
							marginTop: 10,
							marginBottom: 10
						}}><CwLoginButton store={store} /></div>
					)}
				</FormGroup>
				{!!(login.error || fb.error) && <FormGroup key="error" error={t(login.error || fb.error)} />}
			</>
		);

	}

}

export const AuthSocial = withTranslation()(AuthSocialClass);
