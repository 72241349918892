import React, { useEffect, useState, useRef } from 'react';
import _uniqBy from 'lodash/uniqBy';
import { useTranslation } from 'react-i18next';
import { MobXProviderContext, observer } from 'mobx-react';
import { Button, HitBox, Modal, ModalContent, ModalTitle, shadeAlternate, styled } from '@lib/components';
import _sortBy from 'lodash/sortBy';
import { Category, Dish, Menu } from './type';

const Btn = styled('div')`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: ${({ theme }) => theme.box.text};
  transition: 0.23s all;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.size}px;

  > .check-circle {
    transition: 0.23s all;
    border: 2px solid ${({ theme }) => shadeAlternate(0.1, theme.box.border)};
    border-radius: 100%;
    width: 16px;
    height: 16px;
    &:after {
      content: ' ';
    }
  }

  &:hover,
  &.active {
    transform: scale(1.015);

    color: ${({ theme }) => theme.colors.primary};
    > .check-circle {
      background: ${({ theme }) => theme.colors.primary};
      width: 16px;
      height: 16px;
    }
  }
  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const FreeItemsWrapper = styled.div`
  max-height: calc(100vh - 190px);
  overflow-y: auto;
  @media (min-width: 1200px) {
    max-height: 450px;
  }
`;

const getDishes = (matchedTier: T.Schema.Restaurant.FreeItemPromo.ConditionTiers, menus: Menu[]) => {
  let items: Dish[] = [];

  if (matchedTier) {
    for (const property in matchedTier.free_items) {
      let key = matchedTier.free_items[property];

      if (key.menu && key.menu.length > 0) {
        menus.forEach((menu: Menu) => {
          if (key.menu.includes(menu._id)) {
            menu.categories.forEach((category: Category) => {
              items = [...items, ...category.dishes];
            });
          }
        });
      }

      if (key.category && key.category.length > 0) {
        menus.forEach((menu: Menu) => {
          menu.categories.forEach((category: Category) => {
            if (key.category.includes(category._id)) {
              items = [...items, ...category.dishes];
            }
          });
        });
      }

      if (key.dish && key.dish.length > 0) {
        menus.forEach((menu: Menu) => {
          menu.categories.forEach((category: Category) => {
            category.dishes.forEach((dish: Dish) => {
              if (key.dish.includes(dish._id)) {
                items = [...items, dish];
              }
            });
          });
        });
      }
    }
  }
  return [...new Set(items)];
};

function FreeItemsSelection() {
  const { store } = React.useContext(MobXProviderContext);
  const { t } = useTranslation();
  const restaurant = store.restaurant;
  const isMobileFullScreen = restaurant.website.sections?.mobiles?.mobile_fullscreen;
  const selectedCount = useRef<number>(0);
  const [dishes, setDishes] = useState([] as Dish[]);
  const [selectedItems, setSelectedItems] = useState([] as Dish[]);
  const cartItemQty = store.cart.s.items.reduce((acc: any, item: any) => acc + item.qty, 0);
  const cartItemAmount = store.cart.total;
  const promo = store.cart.s.promos.length > 0 ? store.cart.s.promos[0] : null;
  let conditionType = promo && promo.condition ? promo.condition.type : null;
  let freeQtyTier: any;
  let freeQty: any;
  switch (conditionType) {
    case 'item_quantity':
      freeQtyTier = store.cart.conditionTierFilter(promo, cartItemQty, 'free_quantity');
      freeQty = freeQtyTier.freeQty;
      break;
    case 'order_amount':
      freeQtyTier = store.cart.conditionTierFilter(promo, cartItemAmount, 'free_quantity');
      freeQty = freeQtyTier.freeQty;
      break;
    default:
      break;
  }

  const selectMode = Number(freeQty) <= 1 ? 'single' : 'multiple';
  const havePromo = promo && promo.type === 'free_item';

  useEffect(() => {
    if (havePromo) {
      setDishes(
        getDishes(freeQtyTier.matchedTier, restaurant.menus).map((item: Dish) => {
          item.free_qty = 0;
          return item;
        })
      );
    } else {
      setDishes([]);
    }
  }, [
    store.cart.s.promos && store.cart.s.promos.length > 0 ? store.cart.s.promos[0].condition?.free_items : null,
    restaurant?.menus,
    havePromo,
  ]);

  const plus = (item: Dish) => {
    if (selectedCount.current > 100) return;
    if (selectedCount.current === Number(freeQty)) return;
    selectedCount.current += 1;
    setDishes(
      dishes.map((dish: Dish) => {
        if (dish._id === item._id) {
          item.free_qty!++;
        }
        return dish;
      })
    );
    const found = selectedItems.findIndex(dish => dish._id === item._id) !== -1;
    if (!found) {
      selectedItems.push(item);
    }
  };

  const subtract = (item: Dish) => {
    if (item.free_qty === 0) return;
    if (selectedCount.current <= Number(freeQty)) {
      selectedCount.current -= 1;
      setDishes(
        dishes.map((dish: Dish) => {
          if (dish._id === item._id) {
            item.free_qty!--;
          }
          return dish;
        })
      );
    }
    const firstFound = selectedItems.findIndex(each => each._id === item._id);
    if (firstFound > -1) {
      selectedItems.splice(firstFound, 1);
    }
  };

  const customEnter = (item: Dish, newValue: number) => {
    if (newValue < 0 || newValue > Number(freeQty) || selectedCount.current === newValue) return;
    let prevValue = selectedCount.current;
    selectedCount.current = newValue;
    setDishes(
      dishes.map((dish: Dish) => {
        if (dish._id === item._id) {
          prevValue = item.free_qty!;
          item.free_qty = newValue;
        }
        return dish;
      })
    );
    const isAdd = newValue > prevValue;
    console.log({ newValue, prevValue, isAdd });
    if (isAdd) {
      const found = selectedItems.findIndex(dish => dish._id === item._id) !== -1;
      if (!found) {
        selectedItems.push(item);
      }
    } else {
      const firstFound = selectedItems.findIndex(each => each._id === item._id);
      if (firstFound > -1) {
        selectedItems.splice(firstFound, 1);
      }
    }
  };

  const onClose = () => {
    selectedCount.current = 0;
    store.modal.toggle('selectFreeItems');
  };
  return (
    <Modal
      id="selectFreeItems"
      width={420}
      closeButton={true}
      active={store.modal.isVisible('selectFreeItems')}
      close={() => onClose()}
      isFullScreen={isMobileFullScreen}
    >
      <ModalTitle paddinglr={25} paddingtb={20} className="round-top">
        <div>
          <h4 className="font-16">{t('store.modals.free_items_promo.title')}</h4>
          <p className="m-t-2">
            {selectMode === 'multiple'
              ? t('store.modals.free_items_promo.free_items_selection.multiple.description', {
                  number: Number(freeQty),
                })
              : t('store.modals.free_items_promo.free_items_selection.single.description', { number: Number(freeQty) })}
          </p>
        </div>
      </ModalTitle>

      <FreeItemsWrapper>
        {selectMode === 'multiple' ? (
          <>
            {dishes.map((item: Dish, index) => (
              <ModalContent paddingtb={5} paddinglr={15} className="flex-l-r-center" key={index}>
                <p>{item.name}</p>
                <HitBox
                  up={() => plus(item)}
                  down={() => subtract(item)}
                  value={item.free_qty!}
                  editable
                  onChange={e => {
                    const parsed = parseInt(e, 10);
                    let val = 0;
                    if (!isNaN(parsed)) {
                      if (parsed < 0) val = parsed * -1;
                      else if (parsed > 100) val = 100;
                      else val = parsed;
                    }
                    customEnter(item, val);
                  }}
                />
              </ModalContent>
            ))}
          </>
        ) : (
          <>
            {dishes.map((item: Dish, index) => (
              <ModalContent paddingtb={10} paddinglr={15} className="flex-l-r-center" key={index}>
                <p>{item.name}</p>
                <Btn
                  className={selectedItems.find(e => e?._id === item._id) ? 'active' : ''}
                  onClick={() => {
                    setSelectedItems([item]);
                  }}
                >
                  <span className="check-circle" />
                </Btn>
              </ModalContent>
            ))}
          </>
        )}
      </FreeItemsWrapper>

      <ModalContent>
        <Button
          full={true}
          color="primary"
          type="submit"
          onClick={() => {
            const freeOrderDishes = _uniqBy(selectedItems, d => d._id).map((dish: Dish) => {
              const orderDish: T.Schema.Order.OrderDish = {
                ...dish,
                qty: dish.free_qty || 1,
                notes: '',
                price: 0,
                base_price: 0,
                option_sets: [],
                isFreeItems: true,
              };
              return orderDish;
            });
            selectedCount.current = 0;
            setSelectedItems([]);
            store.cart.addFreeItemsToCart(freeOrderDishes);
            localStorage.setItem('isSelectedFreeItem', 'true');
            store.modal.show('cart');
          }}
        >
          {t('store.modals.free_items_promo.free_items_selection.confirm_button')}
        </Button>
      </ModalContent>
    </Modal>
  );
}

export const FreeItemsSelectionModal = observer(FreeItemsSelection);
