import * as React from "react";
import { observer, inject } from "mobx-react";
import { Modal, ModalContent } from "@lib/components";
import { OrderConfigServices } from "./stages/services";
import { OrderConfigAddressGoogle } from "./stages/address.google";
import { OrderConfigAddressCustom } from "./stages/address.custom";
import { OrderConfigDue } from "./stages/due";
import { OrderConfigDatetime } from "./stages/datetime";
import { OrderConfigTables } from "./stages/table";
import { OrderConfigNumberOfPeople } from "./stages/number_of_people";
import { OrderConfigConfirmed } from "./stages/confirmed";
import { BookingConfig } from "./stages/booking";
import { MobxComponent } from "../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation {}
interface State {
}

@inject("store")
@observer
class OrderConfigModalClass extends MobxComponent<Props, State> {
    constructor(props: Props) {
        super(props);
    }
    stages = () => {
        const r = this.injected.store.restaurant;
        const oc = this.injected.store.order_config.s;

        const stages = {
            address: false,
            booking: false,
            when: false,
            datetime: false,
            table: false,
            number_of_people: false,
            confirmed: false,
        };

        if (oc.service === "pickup") {
            stages.when = true;
            if (oc.due === "now") {
                stages.confirmed = true;
            } else if (oc.due === "later") {
                stages.datetime = true;
                if (oc.date && oc.time) {
                    stages.confirmed = true;
                }
            }
        } else if (oc.service === "delivery") {
            stages.address = true;
            if (oc.destination) {
                stages.when = true;
                if (oc.due === "now") {
                    stages.confirmed = true;
                } else if (oc.due === "later") {
                    stages.datetime = true;
                    if (oc.date && oc.time) {
                        stages.confirmed = true;
                    }
                }
            }
        } else if (oc.service === "dine_in") {
            stages.when = true;
            if (oc.due === "now") {
                stages.table = true;
                if (oc.table) {
                    // console.log("4");
                    // console.log("4 - oc.table = " + oc.table);
                    // console.log("4 - oc.table_id = " + oc.table_id);
                    const table = r.settings.services.dine_in.tables.find(
                        (t) => t.name === oc.table
                    );
                    if (
                        table &&
                        (!table.password ||
                            table.password === oc.table_password)
                    ) {
                        stages.confirmed = true;
                    }
                }
            } else if (oc.due === "later") {
                stages.datetime = true;
                if (oc.date && oc.time) {
                    stages.number_of_people = true;
                    if (oc.number_of_people) {
                        stages.confirmed = true;
                    }
                }
            }
        } else if (oc.service === "table_booking") {
            stages.datetime = true;
            if (oc.date && oc.time) {
                stages.booking = true;
            }
        }
        return stages;
    };

    isServicePaused(service: T.Schema.Order.OrderServices) {
        const { store } = this.injected;
        const { operations: ops } = store.restaurant?.settings?.business;
        const now = store.intl.momentNow();

        if (ops) {
            const checkPauseServices =
                ops &&
                ops.services.includes(
                    service == 'table_booking' ? 'booking' : service
                ) &&
                ops.pause_start &&
                ops.pause_end &&
                now.isAfter(store.intl.momentFromMillis(ops?.pause_start)) &&
                now.isBefore(store.intl.momentFromMillis(ops?.pause_end));
            return checkPauseServices;
        }
        return false;
    }

    render() {
        const { store, t } = this.injected;
        const r = store.restaurant;
        const { service_required } = r.settings.business;
        const { modal, orderingDisabled, servicesAllDisabled, isMappedData } = this.injected.store;
        const oc = this.injected.store.order_config.s;
        const stages = this.stages();
        let active: boolean;
        let close_button = false;
        if (service_required) {
            close_button = false;
            if (servicesAllDisabled || orderingDisabled) {
                active = modal.isVisible("order-config");
                close_button = true;
            } else if (!oc.confirmed) {
                active = true;
            } else {
                active = modal.isVisible("order-config");
            }
        } else {
            active = modal.isVisible("order-config");
            close_button = true;
        }
        const checkPauseServices = this.isServicePaused(
            store.order_config.s.service || 'dine_in'
        );
        return (
            <Modal
                id="modal-order-config"
                width="md"
                closeButton={close_button}
                active={active}
                close={() => modal.hide("order-config")}
            >
                {(servicesAllDisabled || orderingDisabled) && (
                    <ModalContent paddingtb={50} paddinglr={15} className="">
                        <p className="big text-center lhp error-text m-t-1">
                            {servicesAllDisabled &&
                                !orderingDisabled &&
                                t("store.modals.order.config.warning.closed")}
                            {(servicesAllDisabled || orderingDisabled) &&
                                t(
                                    "store.modals.order.config.warning.not_available"
                                )}
                        </p>
                    </ModalContent>
                )}

                {!servicesAllDisabled && !orderingDisabled && (
                    <ModalContent paddingtb={50} paddinglr={15} className="">
                        <OrderConfigServices />
                        {stages.address && isMappedData && !checkPauseServices && (
							<OrderConfigAddressGoogle />
						)}
                        {stages.address && !isMappedData && !checkPauseServices &&(
							<OrderConfigAddressCustom />
						)}
                        {checkPauseServices && <div>
                                <p className="big text-center lhp error-text small">
                                    { t(
                                        "store.modals.order.config.warning.not_available"
                                    )}
                                </p></div>}
                        {stages.when && !checkPauseServices && <OrderConfigDue /> }
                        {stages.datetime && !checkPauseServices &&<OrderConfigDatetime />}
                        {stages.table && !checkPauseServices &&<OrderConfigTables />}
                        {stages.number_of_people && (
                            <OrderConfigNumberOfPeople />
                        )}
                        {stages.confirmed && <OrderConfigConfirmed />}
                        {stages.booking && <BookingConfig />}
                    </ModalContent>
                )}
            </Modal>
        );
    }
}

export const OrderConfigModal = withTranslation()(OrderConfigModalClass);
