import * as React from "react";
import cn from "classnames";
import { observer, inject } from "mobx-react";
import { FaHandPointDown, FaClock } from "react-icons/fa";
import { ChoicesWrapper, Choice, ChoiceIcon } from "../components";
import { MobxComponent } from "../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation { }
interface State {
}
@inject("store") @observer
class OrderConfigDueClass extends MobxComponent<Props, State> {
	constructor(props: Props) {
		super(props);

	}

	render() {
		const { t, store } = this.injected;
		const { service, order_config } = store;
		const open = this.injected.store.serviceOpen();
		const nowAvailable = service!.times.now && open;
		const laterAvailable = service!.times.later;

		const nowElement = (
			<Choice
				className="col"
				active={order_config.s.due === "now"}
				disabled={!nowAvailable}
				onClick={() => {
					if (nowAvailable) {
						order_config.setDue("now");
					}
				}}>
				<ChoiceIcon>
					<FaHandPointDown />
				</ChoiceIcon>
				<p>{t("store.modals.order.config.datetime.due_now")}</p>
			</Choice>
		);

		const laterElement = (
			<Choice
				className="col"
				active={order_config.s.due === "later"}
				disabled={!laterAvailable}
				onClick={() => {
					if (laterAvailable) {
						order_config.setDue("later");
					}
				}}>
				<ChoiceIcon>
					<FaClock />
				</ChoiceIcon>
				<p>{t("store.modals.order.config.datetime.due_later")}</p>
			</Choice>
		);

		const serviceWord = t(`constants.services.${order_config.s.service}`);

		console.log(!nowAvailable, !laterAvailable)
		return (
			<div className="m-t-6">
				{((!nowAvailable && !laterAvailable )) && (
					<div>
						<p className="big text-center lhp error-text small">
							{t("store.modals.order.config.datetime.service_not_available", { service: serviceWord.toLowerCase() })}
						</p>
					</div>
				)}
				{(nowAvailable || laterAvailable) && (
					<div>
						<p className={cn("big text-center lhp", nowAvailable ? "m-b-6" : "m-b-3")}>
							{t("store.modals.order.config.datetime.title")}
						</p>
						{!nowAvailable && (
							<p className="m-b-6 text-center small">
								{t("store.modals.order.config.datetime.currently_closed", { service: serviceWord })}
							</p>
						)}
						<ChoicesWrapper className={nowAvailable && laterAvailable ? "grid-2 xs xs-gap max250" : "max150"}>
							{nowAvailable && nowElement}
							{laterAvailable && laterElement}
						</ChoicesWrapper>
					</div>
				)}
			</div>
		);
	}

}

export const OrderConfigDue = withTranslation()(OrderConfigDueClass);
