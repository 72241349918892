import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import { Button, RotateLoader } from "@lib/components";
import { withTheme } from "styled-components";

interface Props extends WithTranslation { }

@inject("store")
@observer
class CheckoutButtonClass extends MobxComponent<Props, {}> {
	interval: any;

	componentDidMount() {
		this.interval = setInterval(() => {
			this.forceUpdate();
		}, 5000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		const { store, theme, t } = this.injected;
		const checkout = store.checkout.s;
		const paymentMethod = checkout.payment;
		const continueMethods: T.Schema.Restaurant.Payments.RestaurantPaymentTypes[] = [
			"paygate_payweb",
			"ipay88",
			"poli_pay",
			"paypal",
			"pesapal",
		];
		let serviceClosesIn = null;
		let serviceClosed = false;
		if (store.order_config.s.due === "now") {
			const ci = store.serviceClosesIn();
			serviceClosesIn = ci ? ci.minutes : null;
			serviceClosed = !store.serviceOpen();
		}

		return (
			<div id="checkout-button">
				{serviceClosesIn !== null && (
					<p className="text-center small error-text m-b-4 lhp">
						{t("store.modals.checkout.warning.closing_soon", {
							value: serviceClosesIn,
						})}
					</p>
				)}
				{serviceClosed && (
					<p className="text-center small error-text m-b-4 lhp">
						{t("store.modals.checkout.warning.closed")}
					</p>
				)}
				{!checkout.paypal_show && (
					<Button
						id="checkoutButton"
						full={true}
						color="primary"
						type="submit"
						disabled={serviceClosed || paymentMethod === 'stripe' && store.checkout.s.stripe_loading_form}
					>
						{!checkout.loading && (
							(paymentMethod !== 'stripe' || (paymentMethod === 'stripe' && !store.checkout.s.stripe_loading_form)) && (
								<span>
								{continueMethods.indexOf(paymentMethod) !== -1
									? t("store.modals.checkout.button.continue")
									: t("store.modals.checkout.button.place_order")}
							</span>
							)
							
						)}
						{paymentMethod !== 'stripe' ? (
							 checkout.loading && (
								<RotateLoader size={2} color={theme.colors.primary_text} />
							)
						):  (
							(checkout.loading || store.checkout.s.stripe_loading_form) && (
							   <RotateLoader size={2} color={theme.colors.primary_text} />
						   )
					   )
						}

					</Button>
				)}
				{checkout.paypal_show && <div id="paypalButton" />}
			</div>
		);
	}
}

// @ts-ignore
export const CheckoutButton = withTheme(withTranslation()(CheckoutButtonClass));
